const PdfSetting = {
  ChartPosition: {
    TOP: 0,
    BOTTOM: 1,
    HIDDEN: -1
  },
  LogoPosition: {
    TOP_LEFT: 0,
    TOP_RIGHT: 1
  },
  LogoWidth: 50
};

export default PdfSetting;