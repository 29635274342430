<template>
  <ag-grid-vue
    v-if="isVisible"
    style="width: 100%; padding-bottom: 16px;"
    class="ag-theme-material"
    :class="{ 'full-table-pagination': pagination }"
    :columnDefs="columnData"
    @grid-ready="gridReady"
    :gridOptions="gridOptions"
    enableCellTextSelection="true"
    rowSelection="multiple"
    :rowMultiSelectWithClick="true"
    :pagination="pagination"
    :paginationPageSize="pageSize > 0 ? pageSize : assetStore.selectedPageSize"
    :defaultColDef="defaultColDef"
    :localeText="localeText"
    @selection-changed="selectionChanged"
    @columnMoved="onColumnMoved"
    :dataTypeDefinitions="dataTypeDefinitions"
    :domLayout="'autoHeight'"
    @columnVisible="onColumnsRemoved"
    :tooltipShowDelay="0"
    :tooltipHideDelay="2000"
    @cellEditingStopped="onCellEditingStopped"
    @keydown="handleKeyDown"
    :autoHeight="false"
    :components="components"
  >
  </ag-grid-vue>
</template>
<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridVue } from "ag-grid-vue3";
import { ref, defineProps, defineEmits, defineExpose, watch, onMounted, onUnmounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useBasketStore } from "@/stores/BasketStore";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const props = defineProps({
  columnDefs: {
    type: Array,
    default: function () {
      return [];
    },
  },
  rowData: {
    type: Array,
    default: function () {
      return [];
    },
  },
  pagination: {
    type: Boolean,
    default: true,
  },
  pageSize: {
    type: Number,
    default: 0,
  },
  selectAll: {
    type: Boolean,
    default: false
  }
});

const gridOptions = {
  alwaysShowVerticalScroll: true,
  suppressScrollOnNewData: true
};

const columnToRemove = ref(null);
const pressedKeyCode = ref(null);
const columnData = ref([]);
const isVisible = ref(true);

const route = useRoute();
const assetStore = computed(() => {
  if (route.path?.startsWith('/portfolio-overview')) {
    return usePortfolioStore();
  } else {
    return useBasketStore();
  }
});

const emit = defineEmits(["selectionChanged", "columnMoved", 'columnRemoved', 'cellEdited']);

const defaultColDef = {
  flex: 1,
  minWidth: 150,
  filter: true,
  editable: false,
  sortable: true,
  resizable: true,
  cellStyle: {
    "padding": "0 8px"
  }
};

const localeText = ref(null);

const dataTypeDefinitions = {
  percentage: {
    extendsDataType: "number",
    baseDataType: "number",
    valueFormatter: (params) =>
      params.value == null ? "" : `${Math.round(params.value * 100)}%`,
  },
};

const onColumnsRemoved = (params) => {
  if (!params.visible && params?.columns?.length === 1) {
    columnToRemove.value = params;
  } else {
    columnToRemove.value = null;
  }
};
const handleKeyDown = (event) => {
  pressedKeyCode.value = event.keyCode;
};
const onCellEditingStopped = (params) => {
  const { rowIndex, column } = params;
  let nextRowIndex, nextColumn;
  emit('cellEdited');
  if (column.colId === 'min_weight') {
    nextRowIndex = rowIndex;
    nextColumn = gridApi.value.getColumnDef('max_weight');
  } 
  else if (column.colId === 'max_weight') {
    nextRowIndex = rowIndex + 1;
    nextColumn = gridApi.value.getColumnDef('min_weight');
  } else if (column.colId === 'fix_weight') {
    nextRowIndex = rowIndex + 1;
    nextColumn = gridApi.value.getColumnDef('fix_weight');
  }
  if (params.newValue !== params.oldValue && nextRowIndex <= props.rowData.length && pressedKeyCode.value === 9) {
    gridApi.value.setFocusedCell(nextRowIndex, nextColumn);
    gridApi.value.startEditingCell({
      rowIndex: nextRowIndex,
      colKey: nextColumn.field,
    });
  }
};
const gridApi = ref(null);
const selectionChanged = (e) => {
  emit("selectionChanged", e);
};
const onColumnMoved = (e) => {
  emit("columnMoved", e);
};
const handleMouseUp = () => {
  if (columnToRemove.value) {
    emit('columnRemoved', columnToRemove.value);
    columnToRemove.value = null;
  }
};
const handleResize = () => {
  if (window.innerWidth < 768) {
    updateTableColumns(props.columnDefs, true);
  } else {
    updateTableColumns(props.columnDefs, false);
  }
  gridApi.value?.refreshCells();
};
const gridReady = (e) => {
  resetLocale();
  gridApi.value = e.api;
  gridApi.value.setRowData(props.rowData);
};
const getSelectedRows = () => gridApi.value?.getSelectedRows();
const updateTableColumns = (activeColumns, hideCheckbox = false) => {
  const fixedColumnsCount = activeColumns?.filter(col => col.pinned === 'left').length;
  const columns = activeColumns.map(col => {
    if (col.pinned === 'left') {
      return {
        ...col,
        lockVisible: !(fixedColumnsCount >= 2)
      }
    } else if (col.pinned === 'right') {
      return {
        ...col,
        lockVisible: true
      }
    } else {
      const column = col;
      if (column.cellDataType === 'bool') {
        column['filterParams'] = {
          valueGetter: (params) => {
            return params.data[column.field] ?
              t('portfolio_overview.columns.true') : t('portfolio_overview.columns.false');
          }
        };
      }
      return column;
    }
  });
  columnData.value = [ 
    {
      field: '',
      suppressMovable: true,
      checkboxSelection: true,
      minWidth: 40,
      maxWidth: 40,
      lockVisible: true,
      filter: false,
      pinned: 'left',
      hide: hideCheckbox
    },
    ...columns
  ];
};
defineExpose({
  getSelectedRows,
});
const resetLocale = () => {
  localeText.value = {
    equals: t('generic.filter_conditions.equals'),
    notEqual: t('generic.filter_conditions.not_equal'),
    blank: t('generic.filter_conditions.blank'),
    notBlank: t('generic.filter_conditions.not_blank'),
    empty: t('generic.filter_conditions.empty'),
    lessThan: t('generic.filter_conditions.less_than'),
    greaterThan: t('generic.filter_conditions.greater_than'),
    lessThanOrEqual: t('generic.filter_conditions.less_than_or_equal'),
    greaterThanOrEqual: t('generic.filter_conditions.greater_than_or_equal'),
    inRange: t('generic.filter_conditions.in_range'),
    inRangeStart: t('generic.filter_conditions.in_range_start'),
    inRangeEnd: t('generic.filter_conditions.in_range_end'),
    contains: t('generic.filter_conditions.contains'),
    notContains: t('generic.filter_conditions.not_contains'),
    startsWith: t('generic.filter_conditions.starts_with'),
    endsWith: t('generic.filter_conditions.ends_with'),
  };
};
const reloadGrid = () => {
  isVisible.value = false;
  setTimeout(() => {
    isVisible.value = true;
    resetLocale();
  }, 1);
};
watch(
  () => locale.value,
  () => {
    reloadGrid();
  }
)
watch(
  () => props.rowData,
  (rowData) => gridApi.value?.setRowData(rowData),
  {
    deep: true,
  },
);
watch(
  () => props.columnDefs,
  (newColumns) => {
    updateTableColumns(newColumns);
  }
)
watch(() => props.selectAll, (newValue) => {
  if (newValue) {
    gridApi.value.getRenderedNodes().forEach((rowNode) => {
      rowNode.setSelected(true);
    });
  } else {
    gridApi.value.deselectAll();
  }
});
onMounted(() => {
  resetLocale();
  updateTableColumns(props.columnDefs);
  document.addEventListener('mouseup', handleMouseUp);
  window.addEventListener('resize', handleResize);
});
onUnmounted(() => {
  document.removeEventListener('mouseup', handleMouseUp);
  window.removeEventListener('resize', handleResize);
});
</script>
<style scoped lang="scss">
:deep(.native_page_select) {
  border: 1px #000 solid;
  border-radius: 4px;
}
:deep(.ag-header-cell) {
  padding-left: 8px;
  background-color: #004851 !important;
  & .ag-header-cell-text {
    color: white !important
  }
}
:deep(.ag-header-cell-label .ag-header-cell-text) {
  white-space: normal !important;
}
:deep(.ag-body.ag-layout-auto-height) {
  max-height: 70vh;
}
:deep(.ag-input-wrapper.ag-checkbox-input-wrapper.ag-checked) {
  background-color: #02b388;
}
:deep(.ag-checkbox.ag-input-field) {
  scale: 0.6;
}
:deep(.ag-checkbox-input-wrapper.ag-checked:after) {
  color: #02b388;
}
.ag-theme-material {
  --ag-background-color: #f2f2f2 !important; /* Replace 'yourCustomColor' with your desired color value */
}
.ag-theme-material .ag-row-hover:not(.ag-row-selected) {
  background-color: inherit !important;
}
.ag-theme-material .ag-row {
  background-color: transparent !important;
}

.ag-theme-material .ag-row:hover {
  background-color: transparent !important;
}

:deep(.ag-paging-panel.ag-unselectable) {
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto !important;
    padding: 16px 0 !important;
  }
}
</style>
