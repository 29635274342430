<template>
  <div class="pdf-printer">
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="685"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1080px"
      @progress="onProgress($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2pdf"
      :html-to-pdf-options="{
        margin: [20, 5, 10, 5],
        filename,
        image: { type: 'jpeg' },
        html2canvas: {
          scale: 0.98,
          useCORS: true,
          letterRendering: true
        },
        jsPDF: { format: 'a4', orientation: 'landscape' }
      }"
    >
      <template v-slot:pdf-content>
        <slot name="body-pdf"></slot>
      </template>
    </vue3-html2pdf>
  </div>
</template>
<script setup>
import { defineProps, defineExpose, ref, defineEmits } from "vue";
import Vue3Html2pdf from "vue3-html2pdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";
import { usePortfolioStore } from "../../stores/PortfolioOverviewStore";
import { useBasketStore } from "../../stores/BasketStore";
import { sortPdfColumns } from "@/utility/helpers";
import PdfSetting from "../../config/pdf/pdf_config";
import pdfFonts from "../../config/pdf/fontString";
import { customNumberFormatter } from "../../utility/helpers";

const { t, n, d, locale } = useI18n();

const html2pdf = ref("html2Pdf");
const authStore = useAuthStore();
const portfolioStore = usePortfolioStore();
const basketStore = useBasketStore();

defineProps({
  disabled: { type: Boolean, default: false },
  filename: { type: String, default: "report" },
});

const emit = defineEmits(["hasDownloaded"]);

const onProgress = (progress) => {
  console.log(progress);
};

const convertToStyleDef = (styleObj) => {
  const convertedStyle = {};
  Object.keys(styleObj).map(key => {
    switch (key) {
      case 'font-size':
        convertedStyle['fontSize'] = styleObj[key];
        break;
      case 'font-weight':
        convertedStyle['fontStyle'] = styleObj[key];
        break;
      case 'font-family':
        convertedStyle['font'] = styleObj[key];
        break;
      case 'color':
        convertedStyle['textColor'] = styleObj[key];
        break;
      case 'background-color':
        convertedStyle['fillColor'] = styleObj[key];
        break;
      case 'text-align':
        convertedStyle['halign'] = styleObj[key];
        break;
      default:
        break;
    }
  });
  return convertedStyle;
};

const drawChart = async (doc, reportType, chart_position, lastY) => {
  let topOffset = chart_position === PdfSetting.ChartPosition.TOP ? 30 : lastY + 20;
  if (chart_position === PdfSetting.ChartPosition.BOTTOM) {
    if (doc.internal.pageSize.height - topOffset < 110) {
      doc.addPage();
      topOffset = 10;
    }
  }
  doc.text(t("portfolio_overview.headings.graph"), 10, topOffset);
  if (reportType === 'asset') {
    const assetGraph = document.getElementById('asset-graph-pdf') || document.getElementById("basket-graph-pdf");
    if (assetGraph) {
      const chartCanvas = await html2canvas(assetGraph);
      const chartImg = chartCanvas.toDataURL("image/jpeg");
      doc.addImage(chartImg, 'jpeg' , 10, topOffset + 1, doc.internal.pageSize.width - 20, 100);
    }
  } else {
    const chartCanvas = await html2canvas(document.getElementById('portfolio-graph-printer'));
    const chartImg = chartCanvas.toDataURL("image/jpeg");
    doc.addImage(chartImg, 'jpeg' , 10, topOffset + 1, doc.internal.pageSize.width - 20, 100);
  }
};

const displaySummary = (doc, startY, printdata, pdfConfig) => {
  let portfolioSummaryData = [];

  if (printdata !== 'basket') {
    doc.text(t(`portfolio_overview.headings.summary`), 10, startY - 5);
    const summary = portfolioStore.getSummary;
    const keys = Object.keys(summary);
    keys.forEach((key) => {
      const element = summary[key];
      portfolioSummaryData.push({
        name: key,
        format: portfolioStore.getColumnFormat(key)?.format,
        decimalQty: portfolioStore.getColumnFormat(key)?.decimalQty,
        values: Object.entries(element).map(([key, value]) => ({
          id: key,
          title: key,
          value: value,
        })),
      });
    });
    const cols = [[ "Portfolio", ...portfolioSummaryData.map(item => item.name) ]];
    const rows = portfolioSummaryData[0].values.map((value, index) => {
      const row = [];
      row.push(value.title);
      portfolioSummaryData.map(item => {
        const format = item?.format || 'decimal';
        const decimalQty = item?.decimalQty || 0;
        const value = item.values[index].value;
        if (["percent", "decimal", "currency"].includes(format)) {
          const formattedValue = customNumberFormatter(n, value, format, decimalQty, locale)
          row.push(formattedValue);
        }
      });
      return row;
    });
    doc.autoTable({
      head: cols,
      body: rows,
      startY,
      headStyles: { ...convertToStyleDef(pdfConfig['columns_header_style']) },
      bodyStyles: { ...convertToStyleDef(pdfConfig['cell-styles']) },
    });
    startY = doc.lastAutoTable.finalY + 10;
  }
  return startY;
};

const drawLogoTitle = async (doc, config, reportType) => {
  const logoPosition = config['report_header'].logo_position;
  const logo = document.createElement('img');
  logo.setAttribute('style', 'position: fixed');
  logo.setAttribute('id', 'pdf-report-logo');
  logo.setAttribute('src', `/img/report/${config['report_header'].logo}`);
  document.body.appendChild(logo);

  const chartCanvas = await html2canvas(logo);
  const chartImg = chartCanvas.toDataURL("image/png");

  const logoWidth = logo.width;
  const logoHeight = logo.height;
  const ratio = logoHeight / logoWidth;

  const title = reportType === 'asset' ? config['report_header']?.asset_report_title : config['report_header']?.portfolio_report_title;
  const titleStyle = convertToStyleDef(config['report_header'].style);
  doc.addFileToVFS('OpenSans-Medium.ttf', pdfFonts['Open Sans']);
  doc.addFileToVFS('OpenSans-Light.ttf', pdfFonts['Open Sans Light']);
  doc.addFileToVFS('OpenSans-Bold.ttf', pdfFonts['Open Sans Bold']);
  doc.addFont('OpenSans-Medium.ttf', 'Open Sans', 'normal');
  doc.addFont('OpenSans-Light.ttf', 'Open Sans Light', 'light');
  doc.addFont('OpenSans-Bold.ttf', 'Open Sans Bold', 'bold');
  doc.setFont(titleStyle.font, titleStyle.fontWeight);
  doc.setFontSize(titleStyle.fontSize);
  doc.setTextColor(titleStyle.textColor);

  if (logoPosition === PdfSetting.LogoPosition.TOP_LEFT) {
    doc.addImage(chartImg, 'png', 10, 1, PdfSetting.LogoWidth, ratio * PdfSetting.LogoWidth);
    doc.text(t(`pdf.${title}`, title), 10, ratio * PdfSetting.LogoWidth + 10);
  } else if (logoPosition === PdfSetting.LogoPosition.TOP_RIGHT) {
    doc.addImage(chartImg, 'png', doc.internal.pageSize.width - (PdfSetting.LogoWidth + 5), 1, PdfSetting.LogoWidth, ratio * PdfSetting.LogoWidth);
    doc.text(t(`pdf.${title}`, title), 10, 15);
  }

  logo.remove();

  doc.setFont('Helvetica', 'normal');
  doc.setFontSize(12);
  doc.setTextColor(0);
};

const getWidthArray = (columns, pdfConfig, title = '') => {
  return columns?.map(column => {
    let configColumnWidth =
      (column.field === 'name' || column.field === 'position_name' || column.field === 'key')
        ? pdfConfig?.name_column_width :
        pdfConfig?.column_width;
    if (title === 'Main positions') {
      configColumnWidth = pdfConfig?.isin_tkr_column_width;
    }
    return {
      field: column.field,
      width: configColumnWidth
    };
  }) ?? [];
};

const getSeparatedCols = (columns, repeated_columns, columnWidthArr, pageWidth) => {
  let seperatedColumns = [];

  const orderedCols = columns.filter(item => !repeated_columns.includes(item.field)).map(column => columnWidthArr.find(item => item.field === column.field));

  let sum = 0;
  columnWidthArr.filter(item => repeated_columns.includes(item.field)).map(item => {
    sum += item.width;
  });
  let arr = [];

  for (let j = 0; j < orderedCols.length; j ++) {
    arr.push(columns.find(item => item.field === orderedCols[j].field));
    sum += orderedCols[j].width;
    if (sum > (pageWidth - orderedCols[j + 1]?.width)) {
      seperatedColumns.push(arr);
      sum = 0;
      columnWidthArr.filter(item => repeated_columns.includes(item.field)).map(item => {
        sum += item.width;
      });
      arr = [];
    }
  }

  seperatedColumns.push(arr);

  return seperatedColumns;
};

const isEnableForOneRow = (firstWidthArr, secondWidthArr, pageWidth) => {
  let firstTableWidth = 0, secondTableWidth = 0;
  firstWidthArr?.map(col => {
    firstTableWidth += col?.width;
  });
  secondWidthArr?.map(col => {
    secondTableWidth += col?.width;
  });
  if (secondWidthArr?.length === 0) secondTableWidth = pageWidth;
  const enable = (firstTableWidth + secondTableWidth) <= pageWidth - 10;
  return { enable, firstTableWidth, secondTableWidth }
};

const drawTableWithColsAndRows = (doc, cols, rows, columns, repeated_columns, pdfConfig, title = '', pageWidth, positionX, positionY) => {
  let startY = positionY, pageNumber = 1;
  const pageHeight = doc.internal.pageSize.height - 10;
  let subArr = cols.filter(subHeader => !repeated_columns.includes(subHeader.field));
  const repeatHeaders = columns.filter(column => repeated_columns.includes(column.field));
  const headerData = [ ...repeatHeaders, ...subArr ]
  const headers = [ headerData.map(item => item.headerName) ];
  const rowData = [
    ...rows.map(row => {
      return [ ...repeatHeaders.map(column => row[column.field]), ...subArr.map(column => row[column.field]) ];
    }),
  ];
  const columnStyles = {};
  for (let j = 0; j < headerData.length; j ++) {
    let headerWidth = 0;
    if (headerData[j].field === 'name' ||
      headerData[j].field === 'position_name' ||
      headerData[j].field === 'key'
    ) {
      headerWidth = pdfConfig?.name_column_width;
    } else if (title === 'Main positions') {
      headerWidth = pdfConfig?.isin_tkr_column_width;
    } else {
      headerWidth = pdfConfig?.column_width;
    }
    columnStyles[j] = { cellWidth: headerWidth };
  }
  if (headers[0].length > 1) {
    pageNumber = doc.internal.getNumberOfPages();
    doc.autoTable({
      head: headers,
      body: rowData,
      startY: startY + 5,
      margin: { left: positionX },
      tableWidth: pageWidth,
      headStyles: { ...convertToStyleDef(pdfConfig['columns_header_style']) },
      bodyStyles: { ...convertToStyleDef(pdfConfig['cell-styles']) },
      columnStyles,
      columnWidth: 'wrap',
      didParseCell: function (data) {
        if (data.cell.raw === 'n/a') data.cell.text = "";
      },
      rowPageBreak: 'avoid'
    });
    startY = doc.lastAutoTable.finalY + 10;
    const bottomSpace = pageHeight - startY;
    if (bottomSpace < 40) {
      doc.addPage();
      startY = 10;
    }
  }
  return { startY, pageNumber};
}

const printReport = async (datasets, printdata , tableCols, tableRows, reportType) => {
  try {
    const pdfConfig = authStore?.pdfConfig;
    const portfolio_columns_to_use = pdfConfig['portfolio_columns_to_use'];
    const chart_position = pdfConfig['chart_position'];
    const default_format = pdfConfig['default_number_format'];
    const prefix = authStore?.config?.parameters?.prefix_new_portfolios;
    const docDirection = pdfConfig['portrait_orientation'] ? 'portrait' : 'landscape';
    const config_compositions = reportType === 'asset' ? pdfConfig?.asset_compositions : pdfConfig?.portfolio_compositions;

    const doc = new jsPDF({
      orientation: docDirection,
      format: 'a4'
    });
    doc.setFont('Helvetica', 'normal');
    doc.setFontSize(12);
    doc.setTextColor(0);

    let orders = pdfConfig['portfolio_table_order'];
    if (reportType === "asset") {
      orders = pdfConfig['basket_table_order'];
    }
    let summaryPosition = orders.indexOf("summary");
    if (orders.indexOf("Asset compositions") < summaryPosition) {
      summaryPosition += datasets.filter(item => Object.keys(config_compositions)?.includes(item.title)).length - 1;
    }
    const sortResults = sortPdfColumns(datasets, orders, reportType);
    const reports = sortResults.map(row => {
      const formats = row.formats;
      const data = row.data.map((element) => {
        const keys = Object.keys(element);
        let rowData = {};
        keys.forEach((columnName) => {
          const value = element[columnName];
          if (value == null || value === "") {
            rowData[columnName] = "";
            return ;
          }
          let format = formats[columnName]?.format;
          let decimalQty = formats[columnName]?.decimalQty;
          if (columnName?.startsWith(prefix) && row?.title === 'positions') {
            format = 'percent';
            decimalQty = 2;
          }
          if (["decimal", "percent"].includes(format)) {
            if (!isNaN(value)) {
              rowData[columnName] = n(value, format, {
                minimumFractionDigits: decimalQty,
              });
            } else {
              rowData[columnName] = "";
            }
          } else if (["boolean", "bool"].includes(format)) {
            value === 0
              ? (rowData[columnName] = t(
                  "portfolio_overview.columns.false",
                  false
                ))
              : (rowData[columnName] = t(
                  "portfolio_overview.columns.true",
                  true
                ));
          } else {
            if (!isNaN(value)) {
              if (row?.title === "Fixed income sectors") {
                rowData[columnName] = n(parseFloat(value), "percent", {
                  minimumFractionDigits: default_format?.decimalQty || 2
                });
              } else {
                rowData[columnName] = n(parseFloat(value), default_format?.format || "decimal", {
                  minimumFractionDigits: default_format?.decimalQty || 2
                });
              }
            } else {
              rowData[columnName] = value;
            }
          }
        });
        return rowData;
      });
      return {
        ...row,
        data,
      };
    });

    await drawLogoTitle(doc, pdfConfig, reportType);

    if (chart_position === PdfSetting.ChartPosition.TOP) {
      await drawChart(doc, reportType, 0);
    }

    const pageWidth = doc.internal.pageSize.width - 10;
    const pageHeight = doc.internal.pageSize.height - 10;
    let startY = chart_position === PdfSetting.ChartPosition.TOP ? 150 : PdfSetting.LogoWidth;

    let shouldContinue = false;
    let compositionTitleWritten = false;

    for (let i = 0; i <= reports.length; i ++) {
      if (shouldContinue) {
        shouldContinue = false;
        continue;
      }
      let nextColumns = [];

      let isReturns = reports[i]?.title === 'Returns';
      let repeated_columns = pdfConfig['columns_to_use_as_id'];
      if (reportType !== 'asset') repeated_columns.push('key');
      if (i === summaryPosition) {
        startY = displaySummary(doc, startY, printdata, pdfConfig);
        const bottomSpace = doc.internal.pageSize.height - startY;
        if (bottomSpace < 40) {
          doc.addPage();
          startY = 10;
        }
      }
      let columns = [], rows = [];
      if (i < reports.length) {
        if (isReturns) {
          doc.text(t(`portfolio_overview.headings.ratios`), 10, startY);
          startY += 10;
        }
        if (Object.keys(config_compositions).includes(reports[i]?.title) && !compositionTitleWritten) {
          compositionTitleWritten = true;
          doc.text(t(`portfolio_overview.headings.Asset compositions`), 10, startY);
          startY += 10;
        }
        columns = reports[i].columns.map(column => {
          const header = {};
          header.headerName = column;
          header.field = column;
          return header;
        });
        nextColumns = i < reports?.length - 1 ? reports[i + 1]?.columns.map(column => {
          const header = {};
          header.headerName = t(`positions_fields.${column}`, column);
          header.field = column;
          return header;
        }) : [];
        if (reportType !== 'asset') {
          columns.unshift({ headerName: t('portfolio_overview.columns.portfolio'), field: 'key' });
          nextColumns.unshift({ headerName: t('portfolio_overview.columns.portfolio'), field: 'key' });
        }
        if (reports[i].title === 'Main positions') {
          repeated_columns = ['position_name'];
        } else {
          repeated_columns.filter(item => item !== 'position_name');
        }
        rows = reports[i].data;
        columns = columns.map(col => {
          if (col.field !== 'key') {
            if (col?.field?.startsWith(prefix)) {
              return {
                ...col,
                headerName: `${t('positions_fields.port')} ${col.field.split('_')[1]}`
              };
            }
            return {
              ...col,
              headerName: t(`positions_fields.${col.field}`, col.field)
            };
          } else {
            return col;
          }
        });
      } else {
        if (reportType === 'asset') continue;
        doc.text(t(`portfolio_overview.headings.assets`), 10, startY);
        columns = tableCols.filter(col => !col.hide && (portfolio_columns_to_use.includes(col.field) || col.field?.startsWith(prefix) || col.field === 'weight'));
        basketStore.populateColumns();
        rows = tableRows.map(row => {
          const newRow = {};
          columns.map(col => {
            try {
              const value = row[col.field];
              const format = basketStore.columns?.find(item => item.key === col.field)?.format || { format: 'percent', decimalQty: 2 };
              if (["decimal", "percent"].includes(format.format)) {
                if (!isNaN(value)) {
                  newRow[col.field] = n(value, format.format, {
                    minimumFractionDigits: format.decimalQty,
                  });
                } else {
                  newRow[col.field] = "";
                }
              } else if (["boolean", "bool"].includes(format.format)) {
                value === 0
                  ? (newRow[col.field] = t(
                      "portfolio_overview.columns.false",
                      false
                    ))
                  : (newRow[col.field] = t(
                      "portfolio_overview.columns.true",
                      true
                    ));
              } else {
                if (col.field === 'name') {
                  newRow[col.field] = value;
                } else if (!isNaN(value)) {
                  newRow[col.field] = n(parseFloat(value), default_format?.format || "decimal", {
                    minimumFractionDigits: default_format?.decimalQty || 2
                  });
                } else if(!isNaN(new Date(value).getDate())){
                  newRow[col.field] = d(new Date(value), 'short');
                } else {
                  newRow[col.field] = value;
                }
              }
            } catch (error) {
              console.error("Error al procesar la fila:", error);
              newRow[col.field] = ""; 
          }
          });
          return newRow;
        });
      }
      
      columns = columns.filter(item => item.field !== 'checked');
      const columnWidthArr = getWidthArray(columns, pdfConfig, reports[i]?.title);
      const nextColumnWidthArr = getWidthArray(nextColumns, pdfConfig, reports[i + 1]?.title);
      
      const compareResult = isEnableForOneRow(columnWidthArr, nextColumnWidthArr, pageWidth);
      
      if (reports[i]?.title && reports[i]?.columns?.length > 0 && reports[i]?.data?.length > 0) {
        doc.text(t(`portfolio_overview.headings.${reports[i]?.title}`), 10, startY);
      }

      if (compareResult.enable && reports[i].title === 'Returns') {
        shouldContinue = true;
        const firstResult = drawTableWithColsAndRows(doc, columns, rows, columns, repeated_columns, pdfConfig, reports[i]?.title, pageWidth, 10, startY);
        doc.setPage(firstResult.pageNumber);
        const offsetX = (compareResult.firstTableWidth / (compareResult.firstTableWidth + compareResult.secondTableWidth)) * pageWidth + 5;
        const secondColumns = nextColumns.filter(item => item.field !== 'checked');
        const secondRows = reports[i + 1].data;
        if (reports[i + 1]?.title){
          doc.text(t(`portfolio_overview.headings.${reports[i + 1]?.title}`), offsetX, startY);
        }
        const secondResult = drawTableWithColsAndRows(doc, secondColumns, secondRows, secondColumns, repeated_columns, pdfConfig, reports[i + 1]?.title, pageWidth, offsetX, startY);
        startY = firstResult.startY > secondResult.startY ? firstResult.startY : secondResult.startY;
      } else {
        const seperatedColumns = getSeparatedCols(columns, repeated_columns, columnWidthArr, pageWidth);
        seperatedColumns.map(subHeaders => {
          startY = drawTableWithColsAndRows(doc, subHeaders, rows, columns, repeated_columns, pdfConfig, reports[i]?.title, pageWidth, 10, startY).startY;
        });
      }
    }

    if (chart_position === PdfSetting.ChartPosition.BOTTOM) {
      await drawChart(doc, reportType, 1, startY);
    }

    for (let i = doc.getNumberOfPages(); i >= 1; i --) {
      const pageContent = doc.internal.pages[i].join('');
      if (pageContent.trim().length < 100) {
        doc.deletePage(i);
      }
    }
    let disclaimerY = doc.lastAutoTable.finalY;
    const disclaimerHeight = docDirection === 'portrait' ? 100 : 60;
    if ((doc.internal.pageSize.height - disclaimerY) < disclaimerHeight) {
      doc.addPage();
      disclaimerY = 10;
    } else {
      disclaimerY = doc.internal.pageSize.height - disclaimerHeight;
    }
    doc.setFontSize(7.5);
    const disclaimerMorningStar = `${t('generic.disclaimer_morning_star')}`;
    doc.text(disclaimerMorningStar, 10, disclaimerY + 10, { maxWidth: doc.internal.pageSize.width - 20 });
    const textDimension = doc.getTextDimensions(disclaimerMorningStar, { maxWidth: doc.internal.pageSize.width - 20 });
    doc.text(t('generic.disclaimer'), 10, disclaimerY + textDimension.h * 3, { maxWidth: doc.internal.pageSize.width - 20 });

    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
      if (pdfConfig['page_footer'].page_number) {
        doc.setPage(i);
        doc.setFontSize(10);
        const date = d(new Date(), 'short');

        if (pdfConfig['page_footer'].date) doc.text(date, pageWidth - 10, pageHeight, { align: 'right' });
        if (pdfConfig['page_footer'].number_of_pages) {
          doc.text(t('pdf.page') + ` ${i} ` + t('pdf.of') + ` ${doc.getNumberOfPages()}`, 10, pageHeight);
        } else {
          doc.text(t('pdf.page') + ` ${i}`, 10, pageHeight);
        }
      }
    }

    authStore.logAction(`${reportType}_pdf_generation`);
    doc.save("report.pdf");
    hasDownloaded(true);
  } catch (error) {
    hasDownloaded(false);
    console.log("Print error: ", error);
  }
};

const hasDownloaded = (e) => {
  emit("hasDownloaded", e);
};

defineExpose({
  printReport,
});
</script>
<style scoped lang="scss">
.pdf-printer :deep(table) {
  width: auto;
}
:deep(.the-graph) .ant-row {
  display: none;
}
</style>
