/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};

const conversionCurrency = (
  transform,
  serie,
  conversion_ratios,
  operand = "multiply"
) => {
  const ratio = conversion_ratios.find((e) => e.isin_tkr === `${transform}`);
  
  const ratioSeriesObj = {};

  ratio.series.forEach((r) => {
    Object.assign(ratioSeriesObj, { [r[0]]: r })
  });
  
  if (ratio) {
    let last_value = 0;
    return serie.map((e) => {
      const eDate = e[0] / 1000;
      const found = ratioSeriesObj[eDate];
      if (found) {
        last_value = found[1];
      }
      return [
        e[0],
        operand === "multiply" ? e[1] * last_value : e[1] / last_value,
      ];
    });
  }
  return serie;
};

const convertGraphCurrency = (serie, currency, to, conversion_ratios) => {
  if (to === "EUR" && currency !== to) {
    return conversionCurrency(
      `${to}/${currency}`,
      serie,
      conversion_ratios,
      "divide"
    );
  } else if (to === "USD" && currency !== to) {
    if (currency === "EUR") {
      return conversionCurrency(
        `${currency}/${to}`,
        serie,
        conversion_ratios,
        "multiply"
      );
    } else {
      const intermediateConversion = conversionCurrency(
        `EUR/${currency}`,
        serie,
        conversion_ratios,
        "divide"
      );
      return conversionCurrency(
        `EUR/${to}`,
        intermediateConversion,
        conversion_ratios,
        "multiply"
      );
      // const ratioToEur = conversion_ratios.find(
      //   (e) => e.isin_tkr === `EUR/${currency}`
      // );
      // const ratio = conversion_ratios.find((e) => e.isin_tkr === `EUR/${to}`);
      // if (ratio) {
      //   let last_value = 0;
      //   let last_value_to_eur = 0;
      //   return serie.map((e) => {
      //     const found = ratio.series.find((r) => r[0] === e[0]);
      //     if (found && found[1]) {
      //       last_value = found[1];
      //     }
      //     const found2 = ratioToEur.series.find((r) => r[0] === e[0]);
      //     if (found2 && found2[1]) {
      //       last_value_to_eur = found2[1];
      //     }
      //     return [e[0], (e[1] / last_value_to_eur) * last_value];
      //   });
      // }
      // return conversionCurrency(
      //   `${to}/EUR`, // Puedes cambiar "EUR" por la moneda base si es diferente
      //   conversionCurrency(
      //     `${to}/${currency}`,
      //     serie,
      //     conversion_ratios,
      //     "divide"
      //   ),
      //   conversion_ratios,
      //   "multiply"
      // );
    }
  }
  return serie;
};

const downloadFile = (base64String, fileName) => {
  var bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
  var blob = new Blob([bytes], { type: "text/csv" });
  var url = URL.createObjectURL(blob);

  var link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(url);
};

export { ellipsis, convertGraphCurrency, downloadFile };
