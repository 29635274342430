import { defineStore } from "pinia";
import { useFundsStore } from "@/stores/FundsStore";
import { useAuthStore } from "@/stores/AuthStore";
import client from "@/plugins/axios";
import { usePortfolioStore } from "./PortfolioOverviewStore";
import { sortTableColumns } from "../utility/helpers";

const fundsStore = useFundsStore();

export const useBasketStore = defineStore("useBasketStore", {
  state() {
    return {
      fullData: [],
      familyFunds: [],
      columns: [],
      activeColumns: [],
      loadingFamily: false,
      basketUrl: "search-funds",
      plotedValues: [],
      loadingReport: false,
      fromPortfolio: false,
      graphOptions: {},
      selectedPageSize: 20,
    };
  },
  actions: {
    async changeBasketUrl(path) {
      const parts = path.split("/");
      const theSlug = parts[1];

      this.basketUrl = theSlug;
    },
    countAssets() {
      return this.fullData.length;
    },
    addAssetById(theType, theId) {
      this.fullData.pushTickerIfNotExist(theId);
    },
    addAssetByKey(key) {
      const authStore = useAuthStore();
      authStore.logAction('add_asset');
      let f = fundsStore.getFundData(key);
      if (typeof f !== "undefined") {
        this.addAsset(f);
      } else {
        console.warn("Missing found: ", key);
      }
    },
    addAsset(item) {
      const authStore = useAuthStore();
      authStore.logAction('add_asset');
      if (Array.isArray(item)) {
        item.forEach((e) => {
          this.fullData.pushTickerIfNotExist(e);
        });
      } else {
        this.fullData.pushTickerIfNotExist(item);
      }
      this.fullData = [...this.fullData];
    },
    removeAssets(ids) {
      const authStore = useAuthStore();
      authStore.logAction('remove_assets');
      this.fullData = [
        ...this.fullData.filter((e) => !ids.includes(e.isin_tkr_id)),
      ];
      const portfolioStore = usePortfolioStore();
      ids.forEach((id) => portfolioStore.deleteBenchmark(id));
    },
    removeAsset(tiker) {
      this.fullData.removeWhereKey(tiker);
      const portfolioStore = usePortfolioStore();
      portfolioStore.deleteBenchmark(tiker);
    },
    deleteAllAssets() {
      this.fullData = [];
      const portfolioStore = usePortfolioStore();
      portfolioStore.clearBenchmarks();
    },
    async getFundsByFamily(id) {
      this.loadingFamily = true;
      const authStore = useAuthStore(); 
      try {
        return client
          .get(`/family-positions/${id}`)
          .then((response) => {
            this.familyFunds = [];
            response.data?.map(f => {
              if (!this.familyFunds?.find(e => e?.isin_tkr_id === f?.isin_tkr_id)) {
                this.familyFunds.push(f);
              }
            });
            authStore.logAction('get_funds_by_family');
            return response.data;
          })
          .finally(() => {
            this.loadingFamily = false;
          });
      } catch (err) {
        this.loadingFamily = false;
        return (this.error = err);
      }
    },
    addPlotedValues(values) {
      this.plotedValues = values;
    },
    populateColumns() {
      if (this.columns.length) {
        return;
      }
      const authStore = useAuthStore();
      authStore.logAction('populate_columns');
      const fixed_ratio_columns = {};
      Object.keys(authStore.config["fixed_ratio_columns"]).forEach((column) => {
        authStore.config["fixed_ratio_columns"][column].forEach((row) => {
          fixed_ratio_columns[`${column}_${row.year}`] = row;
        });
      });
      const keys = [
        ...new Set(
          Object.keys(authStore.config["master_funds_columns"]).concat(
            Object.keys(authStore.config["master_stocks_columns"]),
            Object.keys(authStore.config["master_others_columns"]),
            Object.keys(authStore.config["master_etfs_columns"]),
            Object.keys(fixed_ratio_columns),
            Object.keys(authStore.config["ratios_columns"])
          )
        ),
      ];
      const configKeys = {
        ...authStore.config["ratios_columns"],
        ...fixed_ratio_columns,
        ...authStore.config["master_etfs_columns"],
        ...authStore.config["master_others_columns"],
        ...authStore.config["master_stocks_columns"],
        ...authStore.config["master_funds_columns"],
      };
      const columns = [];
      keys.forEach((key) => {
        this.columns.push({
          key,
          active: true,
          format: configKeys[key].format,
        });
        if (configKeys[key].basket) {
          columns.push({
            key,
            active: true,
            format: configKeys[key].format,
          });
        }
      });
      // const tradableColumn ={
      //   key: "tradable",
      //   active: true,
      //   format: { format: "boolean" },
      // };
      // columns.push(tradableColumn);
      // this.columns.push(tradableColumn);
      const defaultOrders = authStore.config["basket_column_order"];
      const orderedColumns = sortTableColumns(columns, defaultOrders);
      this.activeColumns = orderedColumns.map((column) => column.key);
    },
    async loadReports(isin_tkrs) {
      try {
        this.loadingReport = true;
        const params = { isin_tkrs };
        return client
          .get("/report", { params })
          .then((response) => {
            const authStore = useAuthStore();
            const fixed_ratio_columns = {};
            Object.keys(authStore.config["fixed_ratio_columns"]).forEach((column) => {
              authStore.config["fixed_ratio_columns"][column].forEach((row) => {
                fixed_ratio_columns[`${column}_${row.year}`] = row;
              });
            });
            const allColumns = {
              ...authStore.config["ratios_columns"],
              ...fixed_ratio_columns,
              ...authStore.config["master_etfs_columns"],
              ...authStore.config["master_others_columns"],
              ...authStore.config["master_stocks_columns"],
              ...authStore.config["master_funds_columns"],
            };
            const responseData = response.data.data;
            const asset_columns_to_use = authStore.pdfConfig?.asset_columns_to_use;
            const asset_information = {};
            const asset_compositions = {};
            const main_positions = {};
            const isin_tkrs = responseData['isin_tkr'] || {};
            Object.keys(responseData).map(key => {
              if (asset_columns_to_use.includes(key)) {
                asset_information[key] = responseData[key];
                return ;
              }
              if (Object.values(isin_tkrs).includes(key)) {
                main_positions[key] = responseData[key];
                return ;
              }
              if (key === 'position_name') {
                main_positions[key] = responseData[key];
                return ;
              }
              asset_compositions[key] = responseData[key];
            });
            asset_compositions["Asset Information"] = asset_information;
            const funds_quantity = authStore?.config['pdf_config']['funds_quantity'];
            if (Object.values(isin_tkrs).length <= funds_quantity) {
              asset_compositions["Main positions"] = main_positions;
            }
            if (!asset_compositions) {
              return [];
            }
            const composition_format = authStore.config?.composition_column_formats;
            const compositions = [];
            const keys = Object.keys(asset_compositions || {});
            keys.forEach((visible) => {
              const keys = Object.keys(asset_compositions[visible] || {});
              const data = [];
              keys.forEach((key) => {
                const element = asset_compositions[visible][key];
                const keys = Object.keys(element || {});
                keys.forEach((key2) => {
                  let index = data.findIndex((element) => element.key === key2);
                  if (index === -1) {
                    data.push({ key: key2 });
                    index = data.length - 1;
                  }
                  data[index][key] = element[key2];
                });
              });
              if (data.length) {
                const rows = data.map(item => {
                  if (visible === "Main positions") {
                    const convertedItem = {};
                    Object.keys(item).map(key => {
                      convertedItem[key] = item[key];
                    });
                    return convertedItem;
                  }
                  else return {
                    ...item,
                    key: Object.values(isin_tkrs)[parseInt(item.key)]
                  };
                });
                compositions.push({
                  title: visible,
                  columns: keys,
                  formats: keys.reduce(
                    (obj, key) => {
                      const columnFormat = allColumns[key]?.format;
                      let format = composition_format[key] || columnFormat;
                      if (!format && Object.values(isin_tkrs)?.includes(key) && visible === 'Main positions') {
                        format = new Proxy({ format: 'percent', decimalQty: 1 }, {});
                      }
                      return { ...obj, [key]: format }
                    },
                    {}
                  ),
                  data: rows,
                });
              }
            });
            authStore.logAction('load_reports');
            return compositions;
          })
          .finally(() => {
            this.loadingReport = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async generateGraph(isins) {
      const authStore = useAuthStore();
      try {
        const data = { isin_tkr_ids: isins, from: authStore.chartRange || '' };
        return client.post("/funds/graph", data).then((response) => {
          authStore.logAction('fund_graphing');
          return response.data.map((serie) => ({
            ...serie,
            series: serie.series.map((e) => {
              return [e[0] * 1000, e[1]];
            }),
          }));
        });
      } catch (err) {
        this.error = err;
      }
    },
    deleteActiveColumn(column) {
      let arr = [...this.activeColumns];
      const index = arr.indexOf(column);
      arr.splice(index, 1);
      this.activeColumns = arr;
    },
  },
  getters: {
    getGraphOptions: (state) => {
      return state.graphOptions;
    },
    getFromPortfolio: (state) => {
      return state.fromPortfolio;
    },
    getBasketUrl: (state) => {
      return state.basketUrl;
    },
    isEmpty() {
      return this.countAssets() <= 0;
    },
    count() {
      return this.fullData.length;
    },
    getActiveColumns(state) {
      const orderedColumns = sortTableColumns(state.columns, state.activeColumns);
      return orderedColumns.filter((column) =>
        state.activeColumns.includes(column.key)
      );
    },
    getInActiveColumns(state) {
      return state.columns.filter((column) =>
        !state.activeColumns.includes(column.key)
      );
    },
    allBasketCols: (state) => {
      return state.columns;
    },
    getPlotedValues: (state) => {
      return state.plotedValues;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "portfolio",
        paths: ["fullData"],
        storage: localStorage,
      },
    ],
  },
});
